// DeepLinkPage.js

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import "./Landing.css"; // Assuming that this is where all your styles are defined

const ContentPreferences = () => {
  const { id } = useParams(); // Assuming your route is defined as '/c/:id'
  const [isLoading, setIsLoading] = useState(false);
  const [isNSFWEnabled, setIsNSFWEnabled] = useState(false);
  const [isFetchingProfile, setIsFetchingProfile] = useState(true);

  useEffect(() => {
    const fetchUserProfile = async () => {
      setIsFetchingProfile(true);
      try {
        const response = await axios.post(
          "https://2vkkfurxek.execute-api.us-east-2.amazonaws.com/production/manageNSFW",
          {
            intent: "getProfile",
            fakeAccountId: id,
          }
        );
        setIsNSFWEnabled(response.data.result.isNSFWEnabled.BOOL || false);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      } finally {
        setIsFetchingProfile(false);
      }
    };

    fetchUserProfile();
  }, [id]);

  const handleNSFWSwitch = async (event) => {
    const newNSFWState = event.target.checked;
    setIsNSFWEnabled(newNSFWState);
    setIsLoading(true);

    try {
      await axios.post(
        "https://2vkkfurxek.execute-api.us-east-2.amazonaws.com/production/manageNSFW",
        {
          intent: "setNSFW",
          fakeAccountId: id,
          isNSFW: newNSFWState,
        }
      );
    } catch (error) {
      console.error("Error updating NSFW preference:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main style={{ backgroundColor: "#171631" }}>
      <Helmet defer={true}>
        <title>Characterverse.ai - Content Preferences</title>
        {/* ... other helmet data as in Landing component */}
      </Helmet>

      {/* ... other shared elements, such as alerts, if needed */}

      <section className="slider-area slider-bg2 second-slider-bg d-flex fix">
        {/* ... stars background and other elements if needed */}
        <div className="container action-space">
          <div className="row">
            <div className="col-lg-12">
              <div className="slider-content second-slider-content text-center">
                {/* ... slogan and subslogan if needed */}
                <h3
                  style={{
                    color: "#ff3494",
                    marginTop: "40px",
                  }}
                >
                  Your Characterverse
                  <br />
                  Content Moderation Preference 👇
                </h3>
              </div>
            </div>
          </div>

          {/* Content moderation switch */}
          <div className="row">
            <div className="col-lg-12">
              <div className="slider-content text-center">
                {isFetchingProfile ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isNSFWEnabled}
                        onChange={handleNSFWSwitch}
                        disabled={isLoading}
                      />
                    }
                    label={
                      isLoading
                        ? "Updating..."
                        : isNSFWEnabled
                        ? "NSFW Content Enabled"
                        : "NSFW Content Disabled"
                    }
                    style={{ color: "#fff", marginTop: "20px" }}
                  />
                )}
              </div>
            </div>
          </div>

          {/* If you want to include links to Play Store or App Store from the original component, you can add them here */}
        </div>
      </section>
    </main>
  );
};

export default ContentPreferences;
