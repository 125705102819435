import React, { useState, useEffect } from "react";
import { MessageCircle, Users, Mic, GamepadIcon, Loader } from "lucide-react";
import "./DeepLinkUser.css";
import { useParams } from "react-router-dom";
import axios from "axios";

const CharacterverseProfile = () => {
  const { username } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      console.log("fetching data", username);
      try {
        const response = await axios.post(
          "https://2vkkfurxek.execute-api.us-east-2.amazonaws.com/production/GUS",
          { username }
        );
        console.log(response.data);
        setProfileData(response.data);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [username]);

  const openInApp = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/.test(navigator.userAgent);
    if (isIOS) {
      window.location.href = "{{IOS_STORE_LINK}}";
    } else if (isAndroid) {
      window.location.href = "{{PLAY_STORE_LINK}}";
    } else {
      window.location.href = "{{IOS_STORE_LINK}}";
    }
  };

  if (isLoading) {
    return (
      <div className="loading-screen">
        <Loader size={48} className="animate-spin" />
        <p>Loading profile...</p>
      </div>
    );
  }

  if (!profileData) {
    return <div className="error-message">Failed to load profile data.</div>;
  }

  const { userDetails, characterList } = profileData;

  return (
    <div className="full-screen-wrapper">
      <header className="header">
        <div className="header-content">
          <div className="header-title">CharacterVerse</div>
          <button onClick={openInApp} className="open-in-app-button">
            Open in App
          </button>
        </div>
      </header>
      <div className="container">
        <div className="profile-section">
          <img src={userDetails.pfp} alt="Profile" className="profile-image" />
          <div className="user-info">
            <div className="user-name-section">
              <h1 className="user-name">{userDetails.username}</h1>
              <div className="action-buttons">
                <button onClick={openInApp} className="action-button">
                  Follow
                </button>
                <button onClick={openInApp} className="action-button">
                  Message
                </button>
              </div>
            </div>
            <div className="stats-container">
              <div className="stat-item">
                <span className="stat-value">{characterList.length}</span>{" "}
                characters
              </div>
              <div className="stat-item">
                <span className="stat-value">{userDetails.followers}</span>{" "}
                followers
              </div>
              <div className="stat-item">
                <span className="stat-value">{userDetails.following}</span>{" "}
                following
              </div>
            </div>
            <div className="bio">
              <p className="bio-name">{userDetails.username}</p>
              <p>{userDetails.bio}</p>
            </div>
          </div>
        </div>

        <div className="tabs-container">
          <div className="active-tab">
            <MessageCircle size={20} />{" "}
            <span className="tab-text">Characters</span>
          </div>
          <div onClick={openInApp}>
            <Users size={20} /> <span className="tab-text">Group Chats</span>
          </div>
          <div onClick={openInApp}>
            <Mic size={20} /> <span className="tab-text">Voices</span>
          </div>
          <div onClick={openInApp}>
            <GamepadIcon size={20} /> <span className="tab-text">Games</span>
          </div>
        </div>

        <div className="character-grid">
          {characterList.map((character, index) => (
            <div key={index} className="character-card">
              <img
                src={character.characterPfp}
                alt={character.characterName}
                className={`character-image ${
                  character.isNSFW ? "blurred-image" : ""
                }`}
              />
              {character.isNSFW && <div className="nsfw-tag">18+</div>}
              <div className="character-info">
                <h3 className="character-name">{character.characterName}</h3>
                <p className="character-bio">{character.characterBio}</p>
                <div className="chat-count">
                  <MessageCircle size={16} />
                  <span>{character.chatCount}</span>
                </div>
              </div>
            </div>
          ))}
          <div className="gradient-overlay">
            <div className="open-in-app-text" onClick={openInApp}>
              Open in App
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CharacterverseProfile;
